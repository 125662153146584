@font-face {
font-family: 'euclidCircular';
src: url(/_next/static/media/204b7f7f8bda7b22-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'euclidCircular';
src: url(/_next/static/media/951bc47d3eb9ba23-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'euclidCircular';
src: url(/_next/static/media/3c6d963638d9080d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'euclidCircular';
src: url(/_next/static/media/21f8953f42de55f0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'euclidCircular Fallback';src: local("Arial");ascent-override: 93.18%;descent-override: 24.97%;line-gap-override: 0.00%;size-adjust: 107.32%
}.__className_efcccd {font-family: 'euclidCircular', 'euclidCircular Fallback'
}.__variable_efcccd {--font-euclid-circular-sans: 'euclidCircular', 'euclidCircular Fallback'
}

